<template>
  <button
    :class="[btnType, loading ? 'is-loading' : '']"
    type="button"
    v-bind="$attrs"
    @click="buttonClick"
  >
    <slot v-if="!loading"></slot>
    <v-progress-circular
      v-else
      indeterminate
      :color="currentColor"
      :size="loaderSize"
      :width="2"
    />
  </button>
</template>
<script>
export default {
  name: "BaseButton",
  props: {
    btnType: {
      type: String,
      default: "mem-primary",
      validator: (val) =>
        [
          "mem-primary",
          "secondary-dark",
          "secondary-light",
          "tertiary-dark",
          "tertiary-light",
        ].includes(val),
    },
    loading: {
      type: Boolean,
      default: false,
    },
    loaderSize: {
      type: Number,
      default: 32,
    },
  },
  computed: {
    currentColor() {
      return this.btnType.toString().includes("dark") ? "white" : "black";
    },
  },
  methods: {
    buttonClick(e) {
      setTimeout(() => {
        document.activeElement.blur();
      }, 200);
      this.$emit("click", e);
    },
  },
};
</script>
<style lang="scss" scoped>
.base-button {
  display: flex;
  justify-content: center;
  align-items: center;
}
button {
  height: 44px;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 22.7053px;
  padding: 0 24px;
  letter-spacing: 0.04em;
  font-size: 14px;
  transition: box-shadow 0.18s ease-in-out;
}
button:disabled {
  pointer-events: none;
}
button:not(.is-disabled):focus {
  box-shadow: 0px 0px 20px #2596ff;
  outline: none;
}
.mem-primary {
  color: #ffffff;
  background: #cf1019;
  opacity: 1;
  transition: opacity 0.2s ease-in;

  &:disabled {
    background-color: #e0e0e0;
    color: #aaaaaa;
  }
}
.mem-primary:hover {
  opacity: 0.7;
}

.secondary-dark {
  color: #191818 !important;
  background: #ffffff;
}
.secondary-dark:disabled {
  background-color: transparent;
  border: 1px solid #999999;
  color: #999999 !important;
}
.secondary-dark:hover:not(:disabled) {
  color: #ffffff !important;
  background: #cf1019;
  // transition: 150ms linear;
}

.secondary-light {
  color: #ffffff;
  background: #121212;
}
.secondary-light:hover:not(:disabled) {
  color: #ffffff;
  background: #cf1019;
}
.secondary-light:disabled {
  background: #d0d0d0;
}

.tertiary-dark {
  border: 1px solid rgba(255, 255, 255, 0.4);
}
.tertiary-dark:hover {
  color: #191818;
  background: #ffffff;
  border: 1px solid #ffffff;
}
.tertiary-dark:disabled {
  border: 1px solid rgba(255, 255, 255, 0.21);
  background: inherit;
  color: rgba(255, 255, 255, 0.2);
  cursor: default !important;
}

.tertiary-light {
  color: #121212;
  border: 1px solid rgba(0, 0, 0, 0.4);
}
.tertiary-light:hover {
  color: #ffffff;
  background: #121212;
  border: 1px solid #121212;
}
.tertiary-light:disabled {
  // border: 1px solid rgba(255, 255, 255, 0.21);
  // background: inherit;
  // color: rgba(255, 255, 255, 0.2);
  // cursor: default !important;
}
.is-loading,
.is-loading:hover,
.is-loading:focus {
  display: block;
  background: transparent !important;
  cursor: default;
  box-shadow: none !important;
}
</style>
